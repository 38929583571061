import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Box,
  Button,
  ChevronDownIcon,
  CloudOffIcon,
  DataLoadingFallback,
  DuplicateIcon,
  getButtonClassName,
  Heading,
  Inline,
  MinusIcon,
  Modal,
  ModalBody,
  ModalFooter,
  OkCloud,
  OkCloudLock,
  PencilIcon,
  PlusIcon,
  PlusMinus,
  Stack,
  Text,
  Time,
  TrashIcon,
  UpRightIcon,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import React, { useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import { Amount } from "../support/Intl"
import { DeleteTransactions, EditTransactionInDialog } from "./Log"
import {
  isTransactionUpdated,
  useTransaction,
  TTransaction,
  TBook,
  BOOK_PERMISSIONS,
  isSharedBook,
  useBook,
  EntryAttachmentInfo,
} from "@cashbook/data-store/books"
import { PerformActionInDialog } from "./TransferActions"
import { usePartyOrContact } from "@cashbook/data-store/books"
import { BillImages, Thumbnail } from "./Attachments"

export function TransactionDetails({
  businessId,
  book: propsBook,
  transactionId,
}: {
  book: TBook
  businessId: string
  transactionId: string
  onBack?: () => void
}) {
  const {
    book,
    involvedUsers,
    checkIfAuthenticatedMemberCan,
    checkIfAuthenticatedMemberCanDoOneOf,
    authMemberDetails,
    isShared,
  } = useBook(propsBook.id)
  const {
    transaction,
    isCashIn,
    isEdited,
    isBillAttached,
    resolveAttributesFromInvolvedUsers,
  } = useTransaction(propsBook, transactionId)
  resolveAttributesFromInvolvedUsers(involvedUsers)
  const moreActionsVisibilityState = useOverlayTriggerState({})
  const activities = useMemo(
    () => getActivites(transaction, authMemberDetails),
    [transaction, authMemberDetails]
  )
  const entryBackedupScreenVisibility = useOverlayTriggerState({})
  const navigate = useNavigate()
  const { partyOrContact } = usePartyOrContact()
  const transactionsAttachment: EntryAttachmentInfo[] = useMemo(() => {
    const attachments = transaction.attachments
      ? Object.values(transaction.attachments)
      : []
    return attachments
  }, [transaction.attachments])

  const fields: { [key: string]: string } = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const t: any = transaction
    const fieldObj: { [key: string]: string } = {}
    Object.keys(book?.customFields || {})
      .filter((key) => t?.[key] && book?.customFields?.[key])
      .sort(
        (a, b) =>
          (book?.customFields?.[a].order || 0) -
          (book?.customFields?.[b].order || 0)
      )
      .forEach((key) => {
        fieldObj[`${book.customFields?.[key].name}`] = t?.[key]
      })
    return fieldObj
  }, [book?.customFields, transaction])

  const fieldsWithFieldIds: { [key: string]: string } = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const t: any = transaction
    const fieldObj: { [key: string]: string } = {}
    Object.keys(book?.customFields || {})
      .filter((key) => t?.[key] && book?.customFields?.[key])
      .forEach((key) => {
        fieldObj[key] = t?.[key]
      })
    return fieldObj
  }, [book?.customFields, transaction])

  return (
    <Box position="relative">
      <ModalBody>
        <Stack gap="4">
          <Stack gap="2" borderWidth="1" rounded="md" padding="4">
            <Inline
              as="header"
              alignItems={{ xs: "start", sm: "center" }}
              justifyContent="between"
              gap="4"
            >
              <Inline
                collapseBelow="sm"
                gap={{ xs: "0", sm: "4" }}
                alignItems={{ xs: "start", sm: "center" }}
                justifyContent="between"
                flex="1"
                fontSize="sm"
              >
                {isCashIn ? (
                  <Inline color="green900" alignItems="center" gap="2">
                    <PlusIcon />
                    <Text as="span">Cash In</Text>
                  </Inline>
                ) : (
                  <Inline color="red900" alignItems="center" gap="2">
                    <MinusIcon />
                    <Text as="span">Cash Out</Text>
                  </Inline>
                )}
                <Text fontWeight="medium">
                  On{" "}
                  <Time
                    timeStamp={transaction.date}
                    format="dd MMM yyyy, hh:mm a"
                  />
                </Text>
              </Inline>
              <Box>
                <Box
                  as="button"
                  onClick={() => {
                    trackEvent(TrackingEvents.ENTRY_SYNC_STATUS_CLICKED, {
                      sharedBook: isShared,
                      billAttached: isBillAttached,
                      role: authMemberDetails.role.id,
                      isEdited: isEdited,
                      synced: true,
                    })
                    entryBackedupScreenVisibility.open()
                  }}
                  title={
                    transaction.createdAt
                      ? "Entry is backed up securely"
                      : "Entry is NOT backed up"
                  }
                >
                  {transaction.createdAt ? (
                    <OkCloud color="green500" />
                  ) : (
                    <CloudOffIcon className="animate-ping" color="gray500" />
                  )}
                </Box>
                <Modal
                  isOpen={entryBackedupScreenVisibility.isOpen}
                  onClose={entryBackedupScreenVisibility.close}
                  title="Entry Backup Details"
                >
                  <ModalBody>
                    <Stack className="text-center" gap="4">
                      <Stack gap="1" alignItems="center">
                        {transaction.createdAt ? (
                          <>
                            <OkCloudLock size="24" color="green500" />
                            <Text fontSize="2xl" fontWeight="medium">
                              Entry is backed up securely
                            </Text>
                          </>
                        ) : (
                          <>
                            <CloudOffIcon size="24" color="gray500" />
                            <Text
                              fontSize="2xl"
                              color="yellow800"
                              fontWeight="medium"
                            >
                              Entry Not backed up
                            </Text>
                          </>
                        )}
                      </Stack>
                      <Text>
                        {transaction.createdAt
                          ? "This entry is 100% safe and securely backed up to the cloud"
                          : "Please connect to internet for regular data backup"}
                      </Text>
                      <Box paddingY="4"></Box>
                    </Stack>
                  </ModalBody>
                </Modal>
              </Box>
            </Inline>
            <Stack gap="4">
              <Inline gap="2" alignItems="center" justifyContent="between">
                <Amount
                  amount={transaction.amount}
                  fontSize="2xl"
                  fontWeight="medium"
                  color={isCashIn ? "green900" : "red900"}
                />
              </Inline>
              <hr />
              <Stack gap="4">
                {transaction.party ? (
                  <Stack gap="2">
                    <Heading fontSize="c2" color="textMedium">
                      {partyOrContact} Name
                    </Heading>
                    <Inline gap="2">
                      <Text fontSize="b3" className="break-words">
                        {transaction.party.name}
                      </Text>
                      {transaction.party.type && (
                        <Text
                          className="break-words"
                          color="textMedium"
                          fontSize="b3"
                        >
                          ({transaction.party.type})
                        </Text>
                      )}
                    </Inline>
                  </Stack>
                ) : null}
                {transaction.remark ? (
                  <Stack gap="2">
                    <Heading fontSize="c2" color="textMedium">
                      Remark
                    </Heading>
                    <Text fontSize="b3" className="break-words">
                      {transaction.remark}
                    </Text>
                  </Stack>
                ) : null}
                {transaction.sourceLoc === "cashbook-payments" ||
                transaction.smsBookEntryId ? (
                  <Stack gap="2">
                    <Text color="textMedium" fontSize="c2">
                      Copied from
                    </Text>
                    <Text fontSize="b3">
                      {transaction.sourceLoc === "cashbook-payments"
                        ? "Payments"
                        : "Passbook"}
                    </Text>
                  </Stack>
                ) : null}
                {transactionsAttachment.length ? (
                  <BillImages attachments={transaction.attachments || {}}>
                    {({ open }) => (
                      <Inline as="ul" gap="6">
                        {transactionsAttachment.map((attachment, i) => (
                          <Box
                            as="li"
                            key={attachment.id}
                            onClick={(e: { stopPropagation: () => void }) => {
                              e.stopPropagation()
                              open(i)
                            }}
                          >
                            <Thumbnail attachment={attachment} />
                          </Box>
                        ))}
                      </Inline>
                    )}
                  </BillImages>
                ) : transaction.imageUrl && transaction.thumbUrl ? (
                  <BillImages
                    attachments={{
                      attachment_jpeg: {
                        id: "attachment_jpeg",
                        url: transaction.imageUrl,
                        thumbUrl: transaction.thumbUrl,
                        mimeType: "images/jpeg",
                        fileName: "attachment_jpeg",
                      },
                    }}
                  >
                    {({ open }) => (
                      <Thumbnail
                        onClick={() => {
                          open(0)
                        }}
                        attachment={{
                          id: "attachment_jpeg",
                          url: transaction.imageUrl || "",
                          thumbUrl: transaction.thumbUrl || "",
                          mimeType: "images/jpeg",
                          fileName: "attachment_jpeg",
                        }}
                      />
                    )}
                  </BillImages>
                ) : null}
                {transaction.category || transaction.paymentMode ? (
                  <Inline gap="4">
                    {transaction.category ? (
                      <Box
                        paddingX="3"
                        paddingY="1"
                        rounded="md"
                        className="bg-category bg-opacity-10 text-category"
                        title="Category"
                      >
                        <Text fontWeight="semibold" fontSize="sm" as="span">
                          {transaction.category.name}
                        </Text>
                      </Box>
                    ) : null}
                    {transaction.paymentMode ? (
                      <Box
                        className="bg-paymentMode bg-opacity-10 text-paymentMode"
                        title="Payment Mode"
                        paddingX="3"
                        paddingY="1"
                        rounded="md"
                      >
                        <Text fontWeight="semibold" fontSize="sm" as="span">
                          {transaction.paymentMode.name}
                        </Text>
                      </Box>
                    ) : null}
                  </Inline>
                ) : null}
              </Stack>
              {Object.keys(fields).length ? (
                <>
                  <hr />
                  <Stack gap="3">
                    <Text fontSize="c1">Custom Information</Text>
                    <Stack as="ul" gap="1">
                      {Object.keys(fields).map((key) => {
                        return (
                          <Inline as="li" key={key}>
                            <Text>
                              {key}:{" "}
                              <Text as="span" color="textMedium">
                                {fields[key]}
                              </Text>
                            </Text>
                          </Inline>
                        )
                      })}
                    </Stack>
                  </Stack>
                </>
              ) : null}
            </Stack>
          </Stack>
          <Stack gap="3">
            <Heading as="h5" fontWeight="medium">
              Activites
            </Heading>
            <Stack as="ol" reversed borderBottomWidth="1">
              {activities.map(({ Icon, body, on }, index) => (
                <React.Fragment key={index}>
                  <Box
                    as="li"
                    position="relative"
                    paddingY="2"
                    style={{ marginLeft: "16px" }}
                  >
                    <Box
                      position="absolute"
                      insetY="0"
                      left="0"
                      display="block"
                      bgColor="gray100"
                      style={{
                        width: "2px",
                      }}
                    ></Box>
                    <Inline
                      gap="4"
                      position="relative"
                      style={{
                        marginLeft: "-15px",
                      }}
                    >
                      <Stack
                        size="8"
                        justifyContent="center"
                        alignItems="center"
                        borderWidth="1"
                        borderColor="white"
                        bgColor="gray100"
                        rounded="full"
                      >
                        <Icon size="5" color="gray500" />
                      </Stack>
                      <Stack gap="1">
                        <Text>{body}</Text>
                        {on ? (
                          <Text color="gray500">
                            On{" "}
                            <Time
                              timeStamp={on}
                              format="dd MMM yyyy, hh:mm a"
                            />
                          </Text>
                        ) : null}
                      </Stack>
                    </Inline>
                  </Box>
                </React.Fragment>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        {checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.EDIT_ENTRY) ? (
          <EditTransactionInDialog
            transactionId={transaction.id}
            book={book}
            involvedUsers={involvedUsers}
            customFields={fieldsWithFieldIds}
          >
            {({ edit }) => (
              <>
                <Box display={{ xs: "none", md: "block" }}>
                  <Button level="primary" size="lg" onClick={() => edit()}>
                    <PencilIcon />
                    <Text as="span">Edit</Text>
                  </Button>
                </Box>
                <Link
                  to={`edit`}
                  className={
                    "block md:hidden " +
                    getButtonClassName({
                      level: "primary",
                      size: "lg",
                    })
                  }
                >
                  <PencilIcon />
                  <Text as="span">Edit</Text>
                </Link>
              </>
            )}
          </EditTransactionInDialog>
        ) : null}
        {checkIfAuthenticatedMemberCanDoOneOf(
          BOOK_PERMISSIONS.DELETE_ENTRY,
          BOOK_PERMISSIONS.MOVE_ENTRIES,
          BOOK_PERMISSIONS.COPY_ENTRIES,
          BOOK_PERMISSIONS.ADD_OPPOSITE_ENTRIES
        ) ? (
          <Button
            size="lg"
            onClick={() => {
              if (moreActionsVisibilityState.isOpen) {
                moreActionsVisibilityState.close()
              } else {
                trackEvent(TrackingEvents.ENTRY_THREE_DOTS_CLICKED, {
                  sharedBook: isShared,
                  billAttached: isBillAttached,
                  role: authMemberDetails.role.id,
                  isEdited: isEdited,
                  synced: true,
                })
                moreActionsVisibilityState.open()
              }
            }}
          >
            More Actions
            <ChevronDownIcon
              rotate={moreActionsVisibilityState.isOpen ? "180" : undefined}
            />
          </Button>
        ) : null}
        {checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.DELETE_ENTRY) ? (
          <DeleteTransactions
            book={book}
            onSuccess={() => {
              navigate(
                `/businesses/${businessId}/cashbooks/${book.id}/transactions`
              )
            }}
          >
            {({ onDelete }) => (
              <Button
                status="error"
                level="tertiary"
                size="lg"
                onClick={() => onDelete(transaction)}
              >
                <TrashIcon />
                <Text as="span">Delete</Text>
              </Button>
            )}
          </DeleteTransactions>
        ) : null}
        <PerformActionInDialog
          bookId={book.id}
          businessId={businessId}
          transactions={[transaction]}
        >
          {({ handleAction }) => {
            const actions = (
              [] as Array<{
                action: "move" | "copy" | "opposite"
                title: string
                description: string
                Icon: typeof UpRightIcon
              }>
            )
              .concat(
                checkIfAuthenticatedMemberCan(
                  BOOK_PERMISSIONS.DELETE_ENTRY,
                  BOOK_PERMISSIONS.MOVE_ENTRIES
                )
                  ? [
                      {
                        action: "move",
                        title: "Move Entry",
                        Icon: UpRightIcon,
                        description:
                          "Entry will be moved to the other selected book",
                      },
                    ]
                  : []
              )
              .concat(
                checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.COPY_ENTRIES)
                  ? [
                      {
                        action: "copy",
                        title: "Copy Entry",
                        Icon: DuplicateIcon,
                        description: "Entry will stay in both books",
                      },
                    ]
                  : []
              )
              .concat(
                checkIfAuthenticatedMemberCan(
                  BOOK_PERMISSIONS.ADD_OPPOSITE_ENTRIES
                )
                  ? [
                      {
                        action: "opposite",
                        title: "Copy Opposite Entry",
                        Icon: PlusMinus,
                        description:
                          "Cash In entry will be added as Cash out entry in other book and vice versa",
                      },
                    ]
                  : []
              )
            if (!actions.length) return null
            return (
              <Modal
                title="More Actions"
                isOpen={moreActionsVisibilityState.isOpen}
                onClose={moreActionsVisibilityState.close}
              >
                <ModalBody>
                  <Stack gap="4">
                    <Inline padding="4" bgColor="gray100" rounded="lg" gap="2">
                      <Text fontWeight="medium" fontSize="sm">
                        Transfer Entry
                      </Text>{" "}
                      <Text color="yellow800" fontSize="sm">
                        (Premium Trial Feature.{" "}
                        <Text
                          as={Link}
                          to="/premium?from=entry-transfer-actions"
                          target="_blank"
                          color="blue900"
                          fontWeight="medium"
                        >
                          Learn More
                        </Text>
                        )
                      </Text>
                    </Inline>
                    <Stack
                      as="ul"
                      className="divide-y"
                      rounded="md"
                      overflow="hidden"
                    >
                      {actions.map(({ action, title, description, Icon }) => (
                        <Box as="li" key={action}>
                          <Box
                            as="a"
                            href={`#${action}`}
                            padding="4"
                            display="block"
                            bgColor={{ hover: "blue50" }}
                            className="hover:bg-blue-50 focus:bg-gray-50"
                            onClick={() => {
                              trackEvent(TrackingEvents.ENTRY_ACTION_CLICKED, {
                                sharedBook: isSharedBook(book),
                                synced: true,
                                entryCount: 1,
                                operation: action,
                                from: "singleEntry",
                              })
                              moreActionsVisibilityState.close()
                              handleAction(action)
                            }}
                          >
                            <Inline gap="4">
                              <Box marginTop="1">
                                <Icon />
                              </Box>
                              <Stack gap="1">
                                <Heading
                                  as="h5"
                                  fontWeight="medium"
                                  className="font-medium"
                                >
                                  {title}
                                </Heading>
                                <Text
                                  fontWeight="normal"
                                  fontSize="xs"
                                  color="gray500"
                                >
                                  {description}
                                </Text>
                              </Stack>
                            </Inline>
                          </Box>
                        </Box>
                      ))}
                    </Stack>
                  </Stack>
                </ModalBody>
              </Modal>
            )
          }}
        </PerformActionInDialog>
      </ModalFooter>
    </Box>
  )
}

export function TransactionDetailsInDialog({
  children,
  ...props
}: Omit<React.ComponentProps<typeof TransactionDetails>, "onBack"> & {
  children: (props: { showDetails: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ showDetails: () => state.open() })}
      <Modal
        isOpen={state.isOpen}
        title="Entry Details"
        isDismissable
        onClose={state.close}
        placement="right"
      >
        <SuspenseWithPerf
          fallback={<DataLoadingFallback label="Loading module..." />}
          traceId="loading_functions_module"
        >
          <TransactionDetails {...props} onBack={() => state.close()} />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function getActivites(transaction: TTransaction, authMember: { uid: string }) {
  const { updatedAt, createdAt, updatedBy, updatedByMember, createdByMember } =
    transaction
  const isUpdated = isTransactionUpdated(transaction)
  return [
    {
      Icon: PlusIcon,
      body: (
        <>
          Created <span className="text-gray-500">by</span>{" "}
          {createdByMember?.id !== authMember.uid
            ? createdByMember?.name || "You"
            : "You"}
        </>
      ),
      on: createdAt,
    },
  ].concat(
    isUpdated && updatedBy
      ? [
          {
            Icon: PencilIcon,
            body: (
              <>
                Last Updated <span className="text-gray-500">by</span>{" "}
                {updatedByMember?.id !== authMember.uid
                  ? updatedByMember?.name || "You"
                  : "You"}
              </>
            ),
            on: updatedAt,
          },
        ]
      : []
  )
}
